<template>
  <div class="internal-server-error">
    <img src="~@/assets/images/sally-logo-full.svg" @click="goBack()"/>
    <div class="wrapper" v-if="isIEBrowser">
      <div class="message">{{$t('not_support_ie1')}}</div>
      <div class="description">
        <span>{{$t('not_support_ie2')}}</span>
        <span>{{$t('not_support_ie3')}}</span>
      </div>
    </div>
    <div class="wrapper" v-else-if="isMobile">
      <div class="message">{{$t('not_support_mobile1')}}</div>
      <div class="description">
        <span>{{$t('not_support_mobile2')}}</span>
      </div>
    </div>
    <div class="wrapper" v-else>
      <div class="icon"></div>
      <div class="message">{{message}}</div>
    </div>
  </div>
</template>

<script>
import bus from '@/common/bus.util';
import { errorType } from '@/assets/js/code';

export default {
  name: 'InternalServerError',
  created() {
    this.message = this.$route.params.message || 'Internal Server Error';
    bus.$emit('end:spinner');
  },
  computed: {
    isIEBrowser() {
      return this.$route.params.type === errorType.NOT_SUPPORT_IE;
    },
    isMobile() {
      return this.$route.params.type === errorType.NOT_SUPPORT_MOBILE;
    },
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    goback() {
      this.$router.go(-2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/variable.scss";
.internal-server-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100px;
  }
  .icon {
    width: 190px;
    height: 153px;
    margin-top: 39px;
    margin-bottom: 36px;
    background-image: url("~@/assets/images/error-500.svg");
    object-fit: contain;
  }
  .message {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #2a324b;
  }
}
</style>
